<template>
  <div class="service">
    <div class="neck_banner">
      <img class="neck_background" src="../assets/service/banner.png"/>
      <div class="neck_text_eng"><span>SERVICE CENTER</span></div>
      <div class="neck_text_chinese">
        <img class="icon_image" src="../assets/service/server_icon.png" />
        <span>服务中心</span>
      </div>
    </div>
    <div class="content-box" id="book">
      <div class="product-books">
        <div class="product-books-top" :class="[(screenWidth < 850 && 'fs-flex-column') || '']">
          <div class="product-books-title">电子图册</div>
          <el-pagination :style="{ 'margin-top': `${(screenWidth < 850 && 20) || 0}px'` }" layout="prev, pager, next"
            :page-size="pageSize" :page-count="totalPage" @prev-click="prevClick" @next-click="nextClick"
            @current-change="changePage" />
        </div>
        <div class="product-books-detail">
          <template v-for="(item, index) in files" :key="index">
            <div class="product-books-box">
              <span class="product-books-text">{{ item.pdfTitle }}</span>
              <el-image class="book-front-page" :src="`/api/ffs/file/${item.titleImage.id}`" fit="cover" />
              <div class="product-books-handle">
                <el-link link target="_blank" :underline="false" :href="`/pdf?id=${item.imageId}`">
                  <span>在线浏览</span>
                </el-link>
                <el-link :underline="false" :href="`/api/ffs/file/${item.pdf.id}`">
                  <span class="product-books-button">立即下载</span>
                </el-link>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="content-stripe purchase-platform-backgroud">
      <div class="content-box" id="online">
        <div class="purchase-platform fs-flex fs-flex-align-center">
          <div class="purchase-platform-bottom fs-flex-between"
            :class="[(screenWidth < 1350 && 'fs-flex-column') || '']">

            <div class="purchase-platform-bottom-left">
              <div class="purchase-platform-bottom-left-textCh">线上采购</div>
              <div class="purchase-platform-bottom-left-textEn">Online purchase</div>
              <div>
                <img src="../assets/service/tail2.png" alt="" />
              </div>
            </div>
            <div class="purchase-platform-bottom-right" :class="[(screenWidth < 1046 && 'fs-flex-column') || '']">
              <div class="tail3_box fs-flex fs-flex-align-center fs-flex-center">
                <div>
                  <a target="_blank" :href="buyOnline?.link">
                    <img v-if="buyOnline?.onlineLogo?.id" :src="`/api/ffs/file/${buyOnline?.onlineLogo?.id}`" alt="" />
                    <p class="onlineStore" style="margin-top: 30px;">{{ buyOnline?.onlineTitle }}</p>
                  </a>
                </div>
              </div>
              <div class="tail4_box fs-flex fs-flex-align-center fs-flex-center">
                <div class="tail5_box">
                  <div class="tail5_box_inner">
                    <img v-if="buyOnline?.appImage?.id" :src="`/api/ffs/file/${buyOnline?.appImage?.id}`" alt="" style="padding: 5px;" />
                  </div>
                  <p style="margin-top: 30px;">{{ buyOnline?.appTitle }}</p>
                </div>
              </div>
              <div class="tail4_box fs-flex fs-flex-align-center fs-flex-center">
                <div class="tail5_box">
                  <div class="tail5_box_inner">
                    <img v-if="buyOnline?.appletImage?.id" :src="`/api/ffs/file/${buyOnline?.appletImage?.id}`" alt="" style="padding: 5px;" />
                  </div>
                  <p style="margin-top: 30px;">{{ buyOnline?.appletTitle }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { inject } from "vue";
import request from "@/utils/request";

import { mapGetters } from "vuex";
export default {


  setup() {
    // 指示当前页的名称，以便使菜单显示高亮；
    const pageIndicator = inject("pageIndicator");
    pageIndicator.currentPageName = "service";
    pageIndicator.lightMode = false; // 导航栏背景适应浅色内容模式；
    pageIndicator.headerTransparent = false; // 导航栏背景全透明；
    pageIndicator.headerGradient = false; // 导航栏背景渐变；
  },

  data() {
    return {
      files: [], //电子图册列表
      totalPage: 0,
      currentPage: 0,
      pageSize: 4,
      clickHere: "", //按钮点击类型
      clickIndex: "", //按钮点击下标
      buyOnline: undefined,
    };
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["screenWidth"]),
    bookHeight() {
      return {
        height: (((window.screen.availWidth - "183px") * 0.91) / 4) * 1.4275,
      };
    },
  },
  methods: {
    getData() {
      const condition =
      {
        page: this.currentPage,
        size: this.pageSize,
      };
      request({
        url: "/api/ui/pdf/page",
        method: "get",
        params: condition,
      })
        .then((res) => {
          if (res.code == 0) {
            //console.log(res.data)
            this.totalPage = res.data.totalPages;
            this.files = res.data.content;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getBuyonline() {
      request({
        url: "/api/ui/buyonline",
        method: "get",
      })
        .then((res) => {
          if (res.code == 0) {
            this.buyOnline = res.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleStoreClick(url) {
      window.open(url, "_blank");
    },
    prevClick() {
      //console.log("prev")
      this.currentPage = this.currentPage - 1;
      this.getData();
    },
    nextClick() {
      //console.log("next")
      this.currentPage = this.currentPage + 1;
      this.getData();
    },
    changePage(e) {
      this.currentPage = e - 1;
      this.getData();
    }
    // handleBookClick(type, index) {
    //   this.clickHere = type;
    //   this.clickIndex = index;
    // },
  },

  mounted() {
    this.getData();
    this.getBuyonline();
    // 锚点；
    const hash = this.$route.hash;
    console.log("----- location=", hash)
    if (hash) {
      const node = document.querySelector(hash);
      if (node) {
        setTimeout(() => {
          node.scrollIntoView({ behavior: "smooth" })
        }, 500);
      }
    }
  },
};
</script>
<style lang="less" scoped>
.service {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.content-box {
  width: 100%;
  max-width: 1920px;
  padding: 0px 0px;

  @media screen and (max-width:960px) {
    padding: 0px;
  }
}

.content-stripe {
  width: 100%;
  padding: 0;
}

.icon_image {
  height: 50px;
  width: 50px;
  margin-bottom: 28px;
  margin-right: 10px;
}

.neck_banner {
  width: 100%;
  height: 500px;
  max-height: 500px;
  // background: url("../assets/service/banner.png") center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  .neck_background{
    object-fit:cover;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

.neck_text_eng {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;

  padding-top: 48px;

  z-index: 10;

  span {
    min-width: 1400px;
    font-size: 140px;
    /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
    font-weight: 800;
    color: #000000;
    opacity: 0.06;
    margin-bottom: 0px;
  }
}

.neck_text_chinese {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 145px;

  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  span {
    font-size: 80px;
    /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
    font-weight: 300;
    color: #000000;
  }
}

@media screen and (max-width: 500px) {
  .neck_text_eng {
    font-size: 30px;
  }

  .neck_text_chinese {
    font-size: 40px;
  }
}

.product-books {
  padding: 83px 90px;
}

@media screen and (max-width: 850px) {
  .product-books {
    padding: 83px 40px;
  }
}

.product-books-top {
  display: flex;
  justify-content: space-between;
}

.book-front-page {
  width: 388px;
  height: 552px;
}

.product-books-title {
  font-size: 40px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #000000;
}

.product-books-detail {
  margin-top: 89px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 20px;
  row-gap: 20px;
}

.product-books-box {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}

.product-books-box:last-child {
  margin-right: none;
}

.product-books-text {
  font-size: 20px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #000000;
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product-books-handle {
  width: 100%;
  margin-top: 32px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 51px;
  row-gap: 20px;
}

.product-books-handle>.el-link {
  cursor: pointer;
  font-size: 20px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
}

.product-books-handle>.el-link:hover {
  text-decoration: underline;
}

.product-books-button .product-books-button-look {
  display: inline-block;
}

.product-books-button-look {
  margin-right: 6%;
}

.activeButton {
  color: #00a0e9;
}

.content-stripe.purchase-platform-backgroud {
  background-color: rgba(152, 154, 166, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.purchase-platform {
  width: 100%;
  //min-height: 1080px;
  min-height: 755px;
  background: url("~@/assets/service/tail1.png") no-repeat left top;
  padding: 0 90px;
  position: relative;
  align-items: flex-end;

  img {
    width: unset;
  }
}

.tail1 {
  height: 370px;
  position: absolute;
  top: 0;
  left: 0;
}

.purchase-platform-bottom {
  display: flex;
  width: 100%;
  //margin-bottom: 170px;
  margin-bottom: 50px;
}

.purchase-platform-bottom-left {
  height: 100%;
}

.purchase-platform-bottom-left-textCh {
  font-size: 40px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #ffffff;
  line-height: 90px;
}

.purchase-platform-bottom-left-textEn {
  font-size: 60px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 500;
  color: #ffffff;
  line-height: 90px;
  margin-bottom: 50px;
}

.purchase-platform-bottom-right {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
  }
}

.tail3_box {
  height: 271px;
  width: 342px;
  margin: 50px 20px;
  background: url("../assets/service/tail3_box.png") no-repeat;
  text-align: center;
  position: relative;
}

.tail4_box {
  height: 413px;
  width: 205px;
  margin: 50px 20px;
  background: url("../assets/service/tail4_box.png") no-repeat;
  text-align: center;
  position: relative;
}

.tail5_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tail5_box_inner {
  padding: 5px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: fit-content;
}

.onlineStore:hover {
  text-decoration: underline;
  color: #A0CFFF;
}
</style>